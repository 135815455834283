import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;
const postJson = h.postJson;
const basicInfo = {

    getExpertInfo(params) {

        return get('/sending/get_expert_info', params);
    },
    // 获取专家信息
    getExpertInfo_v2(params) {

        // return get('/sending/get_expert_info', params);
        return postJson('/info/expert/getExpertInfo2', params);
    },
    getExpertInfoExport(params){
        return post('/info/expert/getExpertInfoExport', params);
    },
    downloadExpertInfoExport(params){
        return post('/info/expert/downloadExpertInfoExport', params);
    },
    // 更新专家等级
    setExpertPriority(params) {
        return post('/sending/set_expertPriority', params);
    },
    setExpertBlock(params) {
        return post('/sending/setExpertBlock', params);
    },
    deleteExpert(params) {
        return post('/sending/deleteExpert', params);
    },
    // 获取院校信息
    getUniversity() {
        return get('/expert/get_university');
    },
    // 获取学科信息
    getAllSubject() {
        return get('/expert/get_all_subjects');
    },
    //修改专家信息
    setExpertDetail(params){
        return post('/expert/setExpertDetail', params);
    },

    // 获取专家信息
    getExpertInfoByExpertId(params)
    {
        return get('/sending/get_expertInfoByExpertId',params);
    },
    // 修改专家信息
    postUpdateExpert(params)
    {
        return post("/sending/update_expert",params);
    },
    // 编辑获取专家信息
    get_expert(params)
    {
        return get('/sending/get_expert',params);
    },
    // 修改专家信息
    update_expert(params){
        return post("/sending/update_expert",params);
    },

    // 获取学校信息
    getInstitutionInfo(params){
        return post("/info/institution/getInstitutionInfo",params);
    },
    // 获取学科信息
    getSubjectInfo(params){
        return post('/info/subject/getSubjectInfo',params);
    },
    // 获取学科 专硕-学硕-本科 信息
    getSubjectList(params){
        return post('/subject/getSubjectList',params);
    },
    // 删除学科信息
    deleteSubject(params){
        return postJson('/subject/deleteSubject',params);
    },
    // 添加学科信息
    addSubject(params){
        return post('/subject/addSubject',params);
    },
}

export default basicInfo