import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const batchManagement = {
    // 获取批次列表
    getBatchDataList(params)
    {
        return post('/batch/getBatchList',params);
    },
    getWorkBatchList(params){
        return post('/batch/getWorkBatchList',params);
    },
    getHasBatchDataList(params){
        return post('/school/getHasDistributionBatchList',params);
    },
    getDistributionBatchList(params){
        return post('/batch/getDistributionBatchList', params);
    },
    getBatch(params)
    {
        return get('/sending/get_batchByOrg',params);
    },

    // 更新存档状态
    updateBatchIsFiled(params)
    {
        return post('/batch/updateBatchIsFiled',params);
    },

    getFiledPaperList(params)
    {
        return post('/batch/getFiledPaperList',params);
    },

    getDistributionUserList(){
        return post('/batch/getDistributionUserList');
    },

    getUserCurBatchList(params){
        return post('/batch/getUserCurBatchList', params)
    },
    getSchoolUserCurBatchList(params){
        return post('/batch/getSchoolUserCurBatchList', params)
    },
    batchDistribution(params){
        return post('/batch/batchDistribution', params)
    },
    insertSchoolBatchDistribution(params){
        return post('/batch/insertSchoolBatchDistribution', params)
    },
    deleteBatchDistribution(params){
        return post('/batch/deleteBatchDistribution', params)
    },
    deleteBatchSchoolDistribution(params){
        return post('/batch/deleteBatchSchoolDistribution', params)
    },
    getDistributionUsers(params){
        return post('/batch/getDistributionUserList', params)
    },

    deleteUserDistribution(params){
        return post('/batch/deleteUserDistribution', params)
    },

    checkBatch(params){
        return post('/batch/checkBatch', params)
    },

    deleteBatch(params){
        return post('/batch/deleteBatch', params)
    }

};

export default batchManagement