import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const authority = {
    // 获取全部全局权限
    getAllPermissions() {
        return get('/admin/global/getAllPermissions');
    },
    //添加角色权限
    // postAddRoleAndPermission(params) {
    //     return post('/admin/roleAndPermission/add', params);
    // },
    postAddRoleAndPermission(params) {
        return post('/authority/postAddRoleAndPermission', params);
    },
    postAddRoleAndPermissionSchool(params) {
        return post('/authority/postAddRoleAndPermissionSchool', params);
    },
    // 获取角色类型
    getPreviewRole() {
        return get('/school/get_previewRole');
    },
    // 获取不同用户的权限清单
    getPermissionsByRole(params) {
        return get('/school/get_permissionsByRole', params);
    },
    getWebRoles(params){
        return post('/authority/getWebRoles', params)
    },
    getWebRolesSchool(params){
        return post('/authority/getWebRolesSchool', params)
    },
    getAuthoritySelect(){
        return post('/authority/getAuthoritySelect')
    },
    getAuthoritySchoolSelect(){
        return post('/authority/getAuthoritySchoolSelect')
    },
    getWebRolesAuthority(params){
        return post('/authority/getWebRolesAuthority',params)
    },
    getWebRolesAuthoritySchool(params){
        return post('/authority/getWebRolesAuthoritySchool',params)
    },
    getWebRolesAllAuthority(params){
        return post('/authority/getWebRolesAllAuthority',params)
    },
    getWebRolesAllAuthoritySchool(params){
        return post('/authority/getWebRolesAllAuthoritySchool',params)
    },
    removeWebRoles(params){
        return post('/authority/removeWebRoles',params)
    },
    removeWebRolesSchool(params){
        return post('/authority/removeWebRolesSchool',params)
    },
    removeUser(params){
        return post('/authority/removeUser',params)
    },
    getExistedAuthList(){
        return post('/authority/getExistedAuthList')
    },
    getExistedAuthListSchool(){
        return post('/authority/getExistedAuthListSchool')
    },
    getDefaultCheckedKeysList(params){
        return post('/authority/getDefaultCheckedKeysList',params)
    },
    getDefaultCheckedKeysListSchool(params){
        return post('/authority/getDefaultCheckedKeysListSchool',params)
    },
    changeDefaultCheckedKeysList(params){
        return post('/authority/changeDefaultCheckedKeysList',params)
    },
    changeDefaultCheckedKeysListSchool(params){
        return post('/authority/changeDefaultCheckedKeysListSchool',params)
    },
    deleteWebRoleAuthority(params){
        return post('/authority/deleteWebRoleAuthority',params)
    },
    deleteWebRoleAuthoritySchool(params){
        return post('/authority/deleteWebRoleAuthoritySchool',params)
    },
    getAddWebRolesList(){
        return post('/authority/getAddWebRolesList')
    },
    getAddWebRolesListSchool(){
        return post('/authority/getAddWebRolesListSchool')
    },
    addWebRoles(params){
        return post('/authority/addWebRoles',params)
    },
    addWebRolesSchool(params){
        return post('/authority/addWebRolesSchool',params)
    }
}

export default authority