import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const financial = {
    getBills(params){
        return get('/sending/get_bills',params);
    },
    // 生成财务表
    postTables(params)
    {
        return post('/sending/generate_financial_tables',params);
    },
    // 导出table
    getTables(params)
    {
        return http.get('/sending/download_excel',{
            params:params,
            responseType: 'blob'
        });
    },
    // 确认打款
    postCheckPay(params)
    {
        return post('/sending/checkPay',params);
    },
    downloadFinancial(params)
    {
        return post('/bill/generateFinancialExcel', params);

    },

    setIsRewardFailed(params)
    {
        return post('/bill/setIsRewardFailed', params);

    },

    revokeIsRewardFailed(params)
    {
        return post('/bill/revokeIsRewardFailed', params);

    },
    getInvoice(params){
        return get('/sending/get_invoice',params);
    },
    // 上传替换文件
    UploadInvoice(params) {
        return http.post('/sending/uploadInvoice', params, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        });
    },
    postUploadBackTable_2(params) {
        return post('sending/uploadBackTable_2', params);
    },
    unPostUploadBackTable(params) {
        return post('sending/unPostUploadBackTable', params);
    },
};

export default financial