import h from '@/api/http/http'

const http = h.http;
const get = h.get;
const post = h.post;

const sendingP2p = {
    // 获取院校批次
    getBatch(params)
    {
        return get('/sending/get_batchByOrg',params);
    },
    getInstitutionAndBatchId(params){
        return get('/sending/p2p/getInstitutionAndBatchId', params);
    },
    // 点对点查询 论文视角
    getP2pPaper(params,cancelToken) {
        return get('/sending/p2p/paper', params);
    },
    getMailDetail(params) {
        return post('/sending/p2p/mailsender', params);
    },
    // ZXR修改的，无视就好
    getAllList(params) {
        return post('/statistics/checkAll', params
            ,
            {
            headers: {
                // 'content-type': 'multipart/form-data'
                'content-type': 'application/json'
                // application/json

            }
        }
        );
    },
    getAllList_login(params) {
        return post('/sending/checkAll', params
            ,
            {
                headers: {
                    // 'content-type': 'multipart/form-data'
                    'content-type': 'application/json'
                    // application/json

                }
            }
        );
    },
    // 点对点查询 评阅书视角
    getP2pResult(params) {
        return get('/sending/p2p/result', params);
    },
    // 点对点查询 专家视角
    getP2pExpert(params) {
        return get('/sending/p2p/expert', params);
    },
    // 点对点匹配
    getMatch(params) {
        return post('/sending/match', params);
    },
    // 自动送审
    postAutoSending(params){
        return post('/sending/setTasks',params);
    },
    postCancalAutoSending(params){
        return post('sending/cancelTasks',params);
    },
    // 获取单篇论文的送审详情
    getSingleCase(params)
    {
        return get('/sending/p2p/get_single_case',params);
    },
    // 发送邀审信息
    getSendEmail(params)
    {
        return get('/sending/send_email',params);
    },
    // 专家审核
    getSelectExpert(params)
    {
        return get("/sending/p2p/select_expert",params);
    },
    // 获取送审论文信息
    getPaperDeatail(params)
    {
        return get('/sending/p2p/get_paper_deatail',params);
    },
    // 添加专家
    postAddExpert(params)
    {
        return post('/sending/add_sender',params);
    },
    postAddExpertSpecial(params)
    {
        return post('/sending/add_sender_special',params);
    },
    // 测回论文
    postRevoke(params)
    {
        return post('/sending/withdraw_invitation',params);
    },
    // 退回论文
    postGoBack(params)
    {
        return post('/sending/back_invitation',params);
    },
    // 恢复论文
    postRenew(params)
    {
        return post('/sending/renew_invitation',params);
    },
    // 匹配方式
    sendUrgeMail(params){
        return get("/sending/send_urgeMail",params);
    },
    // 修改截止时间
    postModifyPaperEndTime(params){
        return post('/school/modifyPaperEndTime',params);
    },
    // 导出评审结果
    generateSendingTable(params){
        return get('/sending/generate_sending_table',params);
    },
    // 导出送审过程
    generateSendingDetailTable(params){
        return get('/sending/generate_sending_detail_table',params);
    },
    // 获取单篇送审规则
    getSingleSendingRule(params){
        return get("/paper/getPaperCondition",params);
    },
    // 修改送审规则
    postModifyCondition(params){
        return post('/paper/modifyCondition',params);
    },
    postModifyConditionSpecial(params){
        return post('/paper/modifyConditionSpecial',params);
    },
    // 批量修改送审规则
    postModifyConditions(params){
        return post('/paper/modifyConditions',params);
    },
    // 导出送审规则表
    downloadPaperCondition(params){
        return post('/paper/download_paper_condition',params);
    },
    downloadPaperConditionSpecial(params){
        return post('/paper/download_paper_condition_special',params);
    },
    // 修改送审记录
    postSendingResult(params){
        return post('/sending/updateSendingResult',params);
    },
    // 修改送审记录(催审记录
    postSendingDetail(params){
        return post('/sending/updateSendingDetail',params);
    },
    // 添加备注
    setPaperNote(params){
        return post('/paper/setPaperNote',params);
    },
    // 获取自动送审详情
    getAutoDetail(params){
        return post('/sending/getAutoDetail',params);
    },
    queryMatchProcess(){
        return post('/sending/queryMatchProcess');
    },
    querySendingProcess(){
        return post('/sending/querySendingProcess');
    },
    //将待送审恢复为待匹配
    recoverWaitingMatch(params){
        return post('/sending/recoverWaitingMatch', params);
    },
    //获取催审备注
    getUrgeDetail(params){
        return get('/sending/p2p/getUrgeDetail',params);
    },
    getUrgeDetailText(params){
        return get('/sending/p2p/getUrgeDetailText',params);
    },
    postAddUrgeText(params){
        return post('/sending/p2p/postAddUrgeText',params);
    },
    // 重新上传论文文件
    replacePaperFile(body){
        // return getJson('/paper/replacePaperFile', params);
        return http.post('/paper/replacePaperFile', body, {
            headers: {
                'content-type': 'multipart/form-data'
            },

        });
    }
};

export default sendingP2p